<template>
  <b-col>
    <b-card
      v-for="bankabilgi in bankaList"
      :key="bankabilgi.sGuid"
    >
    <b-row v-if="form.sBankaAdi != bankabilgi.sBankaAdi">
      <b-col>
        <b-img
            fluid
            class="card-img-top"
            :src="bankabilgi.sLogoUrl"
          />
      </b-col>
      <b-col>
            <h5 class="mt-2">
              {{ bankabilgi.sBankaAdi }}
            </h5>
          <b-link
            class="text-body"
          >
            Hesap No: {{ bankabilgi.sHesapNo }}
          </b-link>
          <b-card-text class="item-company">
              Şube: {{ bankabilgi.sSube }}
          </b-card-text>
        <b-card-text v-if="form.sIban === 0">
          {{ bankabilgi.sIban }}</b-card-text>
      </b-col>
      
      <b-col>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            class="btn-cart move-cart"
            @click="update(bankabilgi)"
          >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>Düzenle</span>
        </b-button>
          <b-button
            variant="light"
            class="btn-wishlist remove-wishlist"
            @click="remove(bankabilgi)"
          >
          <feather-icon icon="XIcon" />
          <span>Kaldır</span>
        </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
        <b-row v-if="form.sBankaAdi === bankabilgi.sBankaAdi">
      <b-col>
        <b-img
            fluid
            class="card-img-top"
            :src="bankabilgi.sLogoUrl"
          />
      </b-col>
      <b-col>
          <b-input v-model="form.sBankaAdi" type="text" class="mt-2" />
          <b-input v-model="form.sHesapNo" type="text" class="mt-2" />
          <b-input v-model="form.sSube" type="text" class="mt-2" />
          <b-input-group>

          <cleave
            :options="prefix"
            v-model="form.sIban"
          />
          </b-input-group>
      </b-col>
      
      <b-col>
        <div class="d-flex align-items-center">
          <div class="d-flex flex-wrap">
          <b-button
            variant="primary"
            class="btn-cart move-cart"
            @click="update(bankabilgi)"
          >
          <feather-icon
            icon="EditIcon"
            class="mr-50"
          />
          <span>Düzenle</span>
        </b-button>
          <b-button
            variant="light"
            class="btn-wishlist remove-wishlist"
            @click="remove(bankabilgi)"
          >
          <feather-icon icon="XIcon" />
          <span>Kaldır</span>
        </b-button>
          </div>
        </div>
      </b-col>
    </b-row>
    </b-card>
  </b-col>

</template>
<script>
import Cleave from 'vue-cleave-component'

export default {
  components: {
    Cleave,
  },
  data() {
    return {
      form: {
        sIban: 0,
        sSube: 0,
        sHesapNo: 0,
        sBankaAdi: 0,
      },
      prefix: {
          prefix: 'TR',
          blocks: [4, 4, 4, 4, 4, 4, 2],
          type: 'numeric',
          uppercase: true,
        },
    }
  },
  computed: {
    bankaList() {
      return this.$store.getters.getBankaBilgis;
    },
  },

  created() {
    this.$store.dispatch('fetchBankaBilgis');
  },
  methods: {

    remove(bankabilgi) {

    },

    update(bankabilgi) {
      if (bankabilgi === this.form) {
        this.form.sBankaAdi = 0;
      } else {
        this.form = bankabilgi
      }
    },
  },
};
</script>

<style>

</style>
